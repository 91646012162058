import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: $props.modalActive,
    style: _normalizeStyle({ width: $props.width, minHeight: $props.height }),
    header: $props.title,
    "onUpdate:visible": $setup.close,
    draggable: true,
    maximizable: false,
    modal: true,
    closable: $props.closeIcon,
    closeOnEscape: true,
    class: _normalizeClass($props.fullScreen ? 'p-dialog-maximized' : ''),
    ref: "test"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Kapat",
        icon: "pi pi-times",
        onClick: $setup.close,
        class: "p-button-text",
        tabindex: "-1"
      }, null, 8, ["onClick"]),
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["visible", "style", "header", "onUpdate:visible", "closable", "class"]))
}