
export default {
  props: ["modalActive", "title", "width", "height", "fullScreen","closeIcon"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return { close };
  },
};
